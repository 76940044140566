import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import axios from 'axios';
import App from "./App.vue";
import VueGtag from "vue-gtag";

const Login = () => import(/* webpackChunkName: "login" */ "./components/Login");

const Feed = () => import(/* webpackChunkName: "feed" */ "./components/Feed");

const ComoFunciona = () => import(/* webpackChunkName: "como-funciona" */ "./components/ComoFunciona");

const Curso = () => import(/* webpackChunkName: "curso" */ "./components/Curso");

const Rodape = () => import(/* webpackChunkName: "rodape" */ "./components/Rodape");

const app = createApp(App);

/* CONFIGS */
app.config.globalProperties.$code = 'uy';
app.config.globalProperties.$analytics = {
    ar: 'G-W2CPZVHT3Q',
    bo: 'G-8BRGTPNZ8L',
    cl: 'G-H9DFBJ0WB9',
    py: 'G-Q424F62GY6',
    uy: 'G-27BLZT2076'
}
app.config.globalProperties.$from = '/';
app.config.globalProperties.$cursos = []; //Courses DB
axios.post(
    'https://'+app.config.globalProperties.$code+'.cocacolaimpulsatunegocio.com/cursos/',
    {
        token: '} i--:mg<;~8HeqTT~sHRkr;UY3n2@I(>kM--;GFe3csa.:SYt4[as@HE]Efp1~6',
        fields: [
            'resumo', 'tempo', 'episodios', 'imagem', 'prioridade', 'certificado',
        ],
        query: {
            post_type: 'cursos',
            posts_per_page: -1
        },
    },
    {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
).then(res => {
    res.data.posts.sort( (a,b) => parseInt(a.prioridade) - parseInt(b.prioridade) );

    for(var i=0; i<res.data.posts.length; i++){
        app.config.globalProperties.$cursos[i] = {
            id: res.data.posts[i].slug,
            nome: res.data.posts[i].title,
            imagem: res.data.posts[i].imagem,
            resumo: res.data.posts[i].resumo,
            conteudo: res.data.posts[i].content,
            tempo: res.data.posts[i].tempo,
            certificado_imagem: res.data.posts[i].certificado,
            certificado: false,
            certificado_url: '',
            episodios: [],
        }
        for(var j=0; j<res.data.posts[i].episodios.length; j++){
            app.config.globalProperties.$cursos[i].episodios[j] = {
                titulo: res.data.posts[i].episodios[j].titulo,
                youtube_id: res.data.posts[i].episodios[j].youtube_id,
                status: false,
            }
        }
    }

    /* VUE ROUTER */
    const router = createRouter({
        history: createWebHashHistory(),
        routes: [
            {
                path: "/",
                name: "home",
                components: {
                    Screen: Feed,
                    Rodape
                },
            },
            {
                path: "/login",
                name: "login",
                components: {
                    Screen: Login,
                }
            },
            {
                path: "/como-funciona",
                name: "como-funciona",
                components: {
                    Screen: ComoFunciona,
                    Rodape
                }
            },
            {
                path: "/curso/:curso_id/:video_id",
                name: "curso",
                components: {
                    Screen: Curso,
                    Rodape
                },
                props: { Screen: true },
            },
            {
                path: "/:pathMatch(.*)*",
                redirect: () => {
                    return { path: '/', query: null }
                },
            }
        ],
    });
    
    /* LOGIN REDIRECT */
    router.beforeEach((to, from, next) => {
        
        //Expirado ou sem localStorage necessário
        if(
            !localStorage.timeout
            || localStorage.timeout < Date.now() - 1000 * 60 * 60 * 24 * 7 //7dias
            || !localStorage.nome) {
            localStorage.clear(); //limpa Localstorage
        }
        else{
            localStorage.setItem('timeout', Date.now()); //Define timeout agora ou atualiza
        }
    
        //Sem cursos
        if(!localStorage.cursos) {
            localStorage.cursos = JSON.stringify(app.config.globalProperties.$cursos);
        }
        //Com cursos
        else {
            const current = JSON.parse(localStorage.cursos);
    
            app.config.globalProperties.$cursos.forEach( c => {
                const sameC = Array.isArray(current) ? current.filter( f => f.id == c.id )[0] : null; //pega os dados do current para o curso iterado
                if( sameC ) {
                    c.certificado_url = sameC.certificado_url;
                    c.certificado = sameC.certificado_url;
                    c.episodios.forEach( ep => {
                        const sameEp = Array.isArray(sameC.episodios) ? sameC.episodios.filter( f => f.youtube_id == ep.youtube_id )[0] : null;
                        if( sameEp ) {
                            ep.status = sameEp.status;
                        }
                    } )
                }
                return c;
            });

            localStorage.cursos = JSON.stringify(app.config.globalProperties.$cursos); //Remove os cursos removidos
        }
    
        //Redirecionamentos por página
        if ( to.name !== 'login' && localStorage.nome == undefined ) {
            app.config.globalProperties.$from = to.path;
            localStorage.clear(); //garantia extra
            next('/login');
        }
        else if (to.name == 'login' && localStorage.nome != undefined) {
            next('/');
        }
        else {
    
            fbq('track', 'PageView'); //eslint-disable-line
            
            gtag('event', 'page_view', { //eslint-disable-line
                page_title: to.name,
                page_location: to.path
            });
    
            next();
        }
    });
    
    app.use(router);
    app.use(VueGtag, {
        includes: [
            { id: 'AW-11159688095' },
        ],
        config: { id: app.config.globalProperties.$analytics[app.config.globalProperties.$code] }
    });
    router.isReady().then(() => app.mount("#app"));

});
